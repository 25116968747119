
import authMixin from '~/mixins/auth'
import { AuthenticationType } from '~/lib/enums'

export default {
  mixins: [authMixin],

  props: {
    authType: {
      type: String,
      default: null
    },

    intent: {
      type: String,
      default: null
    }
  },

  computed: {
    AuthenticationType() {
      return AuthenticationType.$all()
    },

    isImplicitSignup() {
      return !this.authType || this.authType === AuthenticationType.Signup
    }
  }
}
