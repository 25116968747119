import { render, staticRenderFns } from "./auth-providers.vue?vue&type=template&id=8ce4b254&scoped=true"
import script from "./auth-providers.vue?vue&type=script&lang=js"
export * from "./auth-providers.vue?vue&type=script&lang=js"
import style0 from "./auth-providers.vue?vue&type=style&index=0&id=8ce4b254&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ce4b254",
  null
  
)

/* custom blocks */
import block0 from "./auth-providers.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconFacebook: require('/home/vsts/work/1/s/components/icon/facebook.vue').default,ZButton: require('/home/vsts/work/1/s/components/style-guide/button.vue').default,IconGoogle: require('/home/vsts/work/1/s/components/icon/google.vue').default,IconApple: require('/home/vsts/work/1/s/components/icon/apple.vue').default,IconEnvelope: require('/home/vsts/work/1/s/components/icon/envelope.vue').default})
