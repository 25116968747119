
/**
 * @typedef SocialAuthConfig
 * @property {string} providerName
 * @property {string} clientId
 * @property {string|array} scope
 * @property {string} authUrl
 * */
/**
 * @typedef SignUpEventParams
 * @property {string} trigger
 * @property {string} isLogin
 * @property {string|array} signupUserType
 * @property {string} provider
 * */

import { SignupUserType } from '~/lib/enums'
import { trackSignupExited, trackSignup } from '~/lib/tracking'
import { UserManager } from 'oidc-client-ts'

export default {
  userManager: UserManager,

  computed: {
    /** @type AppleAuthConfig (UserManagerSettings) */
    appleAuthConfigIdServer() {
      return {
        providerName: 'Apple',
        config: this.$config.appleAuthConfig
      }
    },

    /** @type FacebookAuthConfig (UserManagerSettings) */
    facebookAuthConfigIdServer() {
      return {
        providerName: 'Facebook',
        config: this.$config.facebookAuthConfig
      }
    },

    /** @type GoogleAuthConfig (UserManagerSettings) */
    googleAuthConfigIdServer() {
      return {
        providerName: 'Google',
        config: this.$config.googleAuthConfig
      }
    },

    iframeParentUrl() {
      return this.parentUrl || null
    },

    pageSource() {
      return this.getRouteBaseName(this.$route) || ''
    }
  },

  methods: {
    async registerByEmail(userInfo) {
      userInfo.RtSessionId = this.$store.state.me.rtSessionId
      userInfo.Platform = this.$device.isMobileOrTablet ? 'mobile_web' : 'web'
      try {
        return await this.createAccount(userInfo)
      } catch (err) {
        return Promise.reject(err.response.data)
      }
    },

    async createAccount(userInfo) {
      const user = await this.$axios.$post(`${this.$config.apiUrl}/api/account/register`, userInfo, {
        headers: {
          Platform: userInfo.Platform,
          'Page-Source': this.pageSource
        }
      })

      if (user) {
        return await this.authenticateByEmail({
          email: userInfo.Email,
          password: userInfo.Password
        })
      }
    },

    async authenticateByEmail({ email, password }) {
      const loginFormData = new FormData()
      loginFormData.set('UserName', email)
      loginFormData.set('Password', password)

      try {
        const response = await this.$auth.loginWith('local', {
          data: loginFormData
        })
        if (response.status === 200) {
          this.$cookies.set('access_token', response.data.AccessToken, {
            domain: this.$config.cookieDomain,
            path: '/',
            expires: new Date(response.data.Expires)
          })

          await this.$authUtils.getUserExtraInfo()
        }

        return Promise.resolve(response)
      } catch (err) {
        return Promise.reject(err.response.data)
      }
    },

    facebookAuth(isSignupEvent, intent) {
      this.triggerExternalAuth(this.facebookAuthConfigIdServer, isSignupEvent, intent)
    },

    googleAuth(isSignupEvent, intent) {
      this.triggerExternalAuth(this.googleAuthConfigIdServer, isSignupEvent, intent)
    },

    appleAuth(isSignupEvent, intent) {
      this.triggerExternalAuth(this.appleAuthConfigIdServer, isSignupEvent, intent)
    },

    userTypeByIntent(intent) {
      switch (intent) {
        case 'ListingIntent':
          return SignupUserType.Owner
        case 'BookingIntent':
        case 'EnquiryIntent':
          return SignupUserType.Renter
        default:
          return SignupUserType.Unknown
      }
    },

    async triggerExternalAuth(config, isSignupEvent = false, intent = '') {
      // RTB (control)
      this.$nuxt.$emit('store-booking-request-payload')

      // RTB (variant)
      // Store Checkout trip description
      this.$nuxt.$emit('store-trip-description')

      const userManagerConfig = config.config

      userManagerConfig.extraQueryParams.lang = this.$i18n.locale
      userManagerConfig.extraQueryParams.signUpAs = intent === 'ListingIntent' ? 1 : 2
      userManagerConfig.extraQueryParams.signUpCountry = this.currentCountryCode()
      userManagerConfig.extraQueryParams.isSignUp = isSignupEvent
      userManagerConfig.extraQueryParams.platform = this.$device.isMobileOrTablet ? 'mobile_web' : 'web'
      userManagerConfig.extraQueryParams.pageSource = this.pageSource

      this.userManager = new UserManager(userManagerConfig)

      if (intent) {
        this.$cookies.set('ris-intent', intent, {
          domain: this.$config.cookieDomain,
          path: '/',
          maxAge: 60 * 60 // 1 hour
        })
      } else {
        this.$cookies.remove('ris-intent', {
          domain: this.$config.cookieDomain,
          path: '/'
        })
      }

      const path = this.$auth.$storage.getUniversal('redirect') || this.$route.fullPath
      this.$auth.$storage.removeUniversal('redirect')

      this.$cookies.set('ris-redirect', path, {
        domain: this.$config.cookieDomain,
        path: '/',
        maxAge: 60 * 60 * 24 * 60 // 60 days
      })

      await this.userManager.signinRedirect()
    },

    async externalAuthenticate({ provider, token, isSignup }) {
      const sixtyDays = 60 * 60 * 24 * 60

      this.$cookies.set('access_token', token, {
        domain: this.$config.cookieDomain,
        path: '/',
        maxAge: sixtyDays
      })

      await this.$auth.setUserToken(`Bearer ${token}`)

      this.$cookies.set('ris-provider', provider, {
        domain: this.$config.cookieDomain,
        path: '/',
        maxAge: sixtyDays
      })

      await this.finalizeSocialAuth(isSignup, provider)
    },

    /**
     * @param {SignUpEventParams} signUpEventParams
     * */
    trackSignupExitedEvent({ isLogin, trigger, isOwner, provider }) {
      if (isLogin === true || trigger === 'event') {
        return
      }
      const eventData = {
        isOwner,
        response: trigger
      }
      if (provider !== null) {
        provider = provider.toLowerCase().replace('facebook', 'fb')
        eventData.signupType = provider
      }

      trackSignupExited(eventData)
    },

    currentCountryCode() {
      const expectedCountries = ['CA', 'US']
      const currentCountry = this.$geolocation.countryCode

      return expectedCountries.includes(currentCountry) ? currentCountry : null
    },

    async finalizeSocialAuth(isSignupEvent, provider) {
      const userType = this.$auth.user.UserType?.toLowerCase()

      await this.$authUtils.getUserExtraInfo()

      if (isSignupEvent) {
        trackSignup({
          userType,
          provider: provider.toLowerCase(),
          userName: this.$auth.user.Email ?? this.$auth.user.UserName,
          email: this.$auth.user.Email ?? this.$auth.user.UserName,
          phone: this.$auth.user.PhoneNumber,
          firstName: this.$auth.user.FirstName,
          lastName: this.$auth.user.LastName
        })
      }

      this.$identifyUser(this.$auth.user)

      this.$authUtils.redirectSuggestedAfterAuthSuccess = () => {
        const isNewOwner = Boolean(userType === 'owner' && isSignupEvent)

        const intent = this.$cookies.get('ris-intent', {
          domain: this.$config.cookieDomain,
          path: '/'
        })

        const redirectURL = this.$cookies.get('ris-redirect', {
          domain: this.$config.cookieDomain,
          path: '/'
        })

        this.$cookies.remove('ris-intent', {
          domain: this.$config.cookieDomain,
          path: '/'
        })
        this.$cookies.remove('ris-redirect', {
          domain: this.$config.cookieDomain,
          path: '/'
        })

        if (intent) {
          if (intent === 'ListingIntent') {
            this.$router.push(this.localePath('dashboard-rvs-new'))
          } else {
            const newRedirectUrl = new URL(`${this.$config.baseURL}${redirectURL}`)
            newRedirectUrl.searchParams.append('intent', intent)
            this.$router.push(newRedirectUrl.toString().replace(this.$config.baseURL, ''))
          }
        } else {
          const isOwnerPage =
            this.getRouteBaseName(this.$route) === 'owner' || redirectURL?.startsWith(this.localePath('owner'))

          if (isNewOwner || isOwnerPage) {
            this.$router.push(this.localePath('dashboard-rvs-new'))
          } else if (userType === 'owner') {
            this.$router.push(this.localePath('dashboard'))
          } else {
            this.$router.push(redirectURL)
          }
        }
      }

      this.$experiment.refreshExperiments().then(() => this.$authUtils.authFinishedWithSuccess())
    }
  }
}
